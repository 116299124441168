import React, {Component} from 'react';
import DashboardNavigation from './DashboardNavigation';
import 'materialize-css';

import {Button, Collapsible, CollapsibleItem, Icon, Modal, Switch, Textarea, TextInput} from "react-materialize";

import "../css/Main.css";
import Dropzone from "react-dropzone";

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export class Dashboard extends Component {
    render() {
        const today = new Date().addDays(7).toISOString().substr(0, 10);
        const newTaskModal = (
            <Modal
                actions={[
                    <Button flat modal="close" node="button" waves="green">Close</Button>
                ]}
                bottomSheet={false}
                fixedFooter
                header="Neue Aufgabe"
                id="Modal-0"
                open={false}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: '4%'
                }}
                trigger={<Button node={"button"} style={{marginRight: 5}} waves={"light"}>Neue Aufgabe<Icon left>add</Icon></Button>}
            >
                Erstellen einer neuen Aufgabe. Bitte Titel und kurze Beschreibung angeben.
                <form>
                    <TextInput
                        data-length={128}
                        id="TextInput-4"
                        label="Title der Aufgabe"
                    />
                    <Textarea
                        data-length={2345}
                        id="Textarea-12"
                        label="Kurze Beschreibung der Aufgabe"
                    />
                    <TextInput id="TextInput-55" type={"date"} label={"Abzugeben bis..."} defaultValue={today} />
                    <Switch
                        id="Switch-11"
                        offLabel="Ohne Dokument"
                        onChange={function noRefCheck(){
                            document.getElementById("new_dropzone").style.display = (document.getElementById("new_dropzone").style.display === "none") ? "block" : "none";
                        }}
                        onLabel="Mit Dokument"
                    />
                    <br/><br/>
                    <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                            <section id={"new_dropzone"} style={{display: "none"}} className={"file_dropzone"}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Dokument hier ablegen oder anklicken zum auswählen...</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <br/><br/>
                    <Button disabled node="button" type="submit" waves={"light"}>
                        Speichern <Icon right>send</Icon>
                    </Button>
                </form>
            </Modal>
        );

        return(
            <>
                <DashboardNavigation />
                <div id="dashboardContent" className="container">
                    <h1>Welcome {this.props.user.username}</h1>
                    <h2>Aktuelle Aufgaben {newTaskModal}</h2>
                    <Collapsible
                        accordion
                        popout
                    >
                        <CollapsibleItem
                            expanded={false}
                            header={<span className={"not_done"}>Aufgabe 1 (abzugeben bis zum 05.05.2020 20:00 Uhr)</span>}
                            icon={<Icon className={"not_done"}>cancel</Icon>}
                            node="div"
                        >
                            <Button node={"button"} style={{marginRight: 5}} waves={"light"}>Download<Icon left>cloud_download</Icon></Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button node={"button"} style={{marginRight: 5}} waves={"light"}>Upload<Icon left>cloud_upload</Icon></Button><br/><br/>
                            Alcatra sirloin turkey rump. Tail filet mignon pork loin short loin pork belly. Shank flank shankle buffalo venison meatball. Shankle tail salami venison bresaola meatloaf doner cow hamburger jowl. Turducken cupim boudin tail picanha kevin doner pork ground round pork loin jerky kielbasa swine corned beef meatloaf. Biltong swine frankfurter rump alcatra porchetta tongue kevin.
                        </CollapsibleItem>
                        <CollapsibleItem
                            expanded={false}
                            header={<span className={"done"}>Aufgabe 2  (abzugeben bis zum 10.05.2020 20:00 Uhr)</span>}
                            icon={<Icon className={"done"}>check_circle</Icon>}
                            node="div"
                        >
                            <Button node={"button"} style={{marginRight: 5}} waves={"light"}>Download<Icon left>cloud_download</Icon></Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button node={"button"} style={{marginRight: 5}} waves={"light"}>Upload<Icon left>cloud_upload</Icon></Button><br/><br/>
                            Alcatra sirloin turkey rump. Tail filet mignon pork loin short loin pork belly. Shank flank shankle buffalo venison meatball. Shankle tail salami venison bresaola meatloaf doner cow hamburger jowl. Turducken cupim boudin tail picanha kevin doner pork ground round pork loin jerky kielbasa swine corned beef meatloaf. Biltong swine frankfurter rump alcatra porchetta tongue kevin.
                        </CollapsibleItem>
                        <CollapsibleItem
                            expanded={false}
                            header={<span className={"not_done"}>Aufgabe 3  (abzugeben bis zum 15.05.2020 20:00 Uhr)</span>}
                            icon={<Icon className={"not_done"}>cancel</Icon>}
                            node="div"
                        >
                            <Button node={"button"} style={{marginRight: 5}} waves={"light"}>Download<Icon left>cloud_download</Icon></Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button node={"button"} style={{marginRight: 5}} waves={"light"}>Upload<Icon left>cloud_upload</Icon></Button><br/><br/>
                            Alcatra sirloin turkey rump. Tail filet mignon pork loin short loin pork belly. Shank flank shankle buffalo venison meatball. Shankle tail salami venison bresaola meatloaf doner cow hamburger jowl. Turducken cupim boudin tail picanha kevin doner pork ground round pork loin jerky kielbasa swine corned beef meatloaf. Biltong swine frankfurter rump alcatra porchetta tongue kevin.
                        </CollapsibleItem>
                    </Collapsible>
                </div>
            </>
        )
    }
}

export default Dashboard;