import React, { Component } from 'react';
import Login from '../components/Login';
import NewPasswordOnLogin from '../components/NewPasswordOnLogin';
import { Auth } from 'aws-amplify';
// import StringHelpers from '../helpers/StringHelpers';
import { Redirect } from 'react-router';

export class LoginContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            loggedIn: false,
            pageLoaded: false,
            newPasswordRequired: false,
            newPassword: "",
            verifyNewPassword: "",
            hasError: "",
            user: {}
        };

        this.updateEmail = this.updateEmail.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateNewPassword = this.updateNewPassword.bind(this);
        this.updateVerifyNewPassword = this.updateVerifyNewPassword.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handlePasswordChallenge = this.handlePasswordChallenge.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onLoginError = this.onLoginError.bind(this);
    }

    updateEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    updatePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    updateNewPassword(e) {
        this.setState({
            newPassword: e.target.value
        });
    }

    updateVerifyNewPassword(e) {
        this.setState({
            verifyNewPassword: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault();

        const email = this.state.email;
        const password = this.state.password;

        this.setState({
            hasError: ''
        });

        Auth.signIn(email, password)
            .then(user => this.onSuccess(user))
            .catch(err => this.onLoginError(err));
    }

    handlePasswordChallenge(e) {
        e.preventDefault();

        this.setState({
            hasError: ''
        });

        const { requiredAttributes } = this.state.user.challengeParam;

        Auth.completeNewPassword(
            this.state.user,
            this.state.newPassword,
            requiredAttributes
        )
            .then(user => this.onSuccess(user))
            .catch(err => this.onLoginError(err));
    }

    onSuccess(user) {
        console.log(user);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.setState({
                newPasswordRequired: true,
                user: user,
            });
        } else {
            this.setState({
                loggedIn: true
            });
        }
    }

    onLoginError(err) {
        console.error(err);

        if(err.code === 'NotAuthorizedException' && err.message === 'Incorrect username or password.') {
            this.setState({
                hasError: 'Email oder Password sind falsch!'
            });
        } else if (err.code === 'NotAuthorizedException') {
            this.setState({
                hasError: 'Etwas ist beim anmelden schief gelaufen!'
            });
        }
    }

    componentDidMount = async () => {
        document.title = "Login";

        try {
            const session = await Auth.currentSession();

            if(session.getIdToken()) {
                this.setState({
                    loggedIn: true,
                    pageLoaded: true
                });
            } else {
                this.setState({
                    loggedIn: false,
                    pageLoaded: true,
                })
            }
        } catch (e) {
            this.setState({
                loggedIn: false,
                pageLoaded: true,
            });
        }
    };

    render() {
        if(!this.state.pageLoaded) {
            return <>Loading...</>
        } else {
            if(this.state.loggedIn) {
                return <Redirect to={"/dashboard"} />;
            } else if(!this.state.newPasswordRequired) {
                return (
                    <Login
                        email={this.state.email}
                        updateEmail={this.updateEmail}
                        password={this.state.password}
                        updatePassword={this.updatePassword}
                        onFormSubmit={this.handleLogin}
                        errorMessage={this.state.hasError}
                    />
                );
            } else {
                // NewPasswordOnLogin
                return (
                    <NewPasswordOnLogin
                        newPassword={this.state.newPassword}
                        verifyNewPassword={this.state.verifyNewPassword}
                        updateNewPassword={this.updateNewPassword}
                        updateVerifyNewPassword={this.updateVerifyNewPassword}
                        onFormSubmit={this.handlePasswordChallenge}
                    />
                )
            }
        }
    }
}

export default LoginContainer;