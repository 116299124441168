import React, { Component } from 'react';

import { InputField } from '../helpers/InputField';
import { Button } from '../helpers/Button';

import { Link } from 'react-router-dom';

import '../css/Login.css';

export class Login extends Component {
    render() {
        return (
            <div id={"wrapper"} className={"row"}>
                <div id={"background-wrapper"} className={"col l1 hide-on-med-and-down"} />

                <div id={"login-wrapper"} className={"col s12 l5"}>
                    <h1>Login</h1>

                    <div className={"row"}>
                        <form id={"login-form"} className={"col s12"}>
                            <div className={"row"}>
                                <InputField
                                    classNames={"col s12"}
                                    name={"mvOemail"}
                                    type={"email"}
                                    validate={true}
                                    autocomplete={false}
                                    label={"E-Mail"}
                                    value={this.props.email}
                                    onchange={this.props.updateEmail}
                                />
                            </div>
                            <div className={"row"}>
                                <InputField
                                    classNames={"col s12"}
                                    name={"mvOpassword"}
                                    type={"password"}
                                    validate={true}
                                    autocomplete={false}
                                    label={"Passwort"}
                                    pattern={".{8,}"}
                                    title={"Passwort benötigt 8 oder mehr Zeichen."}
                                    value={this.props.password}
                                    onchange={this.props.updatePassword}
                                />
                            </div>
                            {this.props.errorMessage && <div className={"error-message"}>{this.props.errorMessage}</div>}
                            <div className={"row"}>
                                <div className={"col s12"}>
                                    <Link className={"right forgotten_link"} to={"/pw_forgotten"}>Passwort vergessen?</Link>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col s12"}>
                                    <Button
                                        type={"submit"}
                                        classNames={"col s5 waves-effect waves-light btn"}
                                        label={"Einloggen"}
                                        clickHandler={this.props.onFormSubmit}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={"col l6 hide-on-med-and-down"} />
            </div>
        )
    }
}

export default Login;