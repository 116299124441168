import React, { Component } from 'react';
import {Auth} from "aws-amplify";
import Account from "../components/Account";
import {Redirect} from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class AccountContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentScreen: "main",
            loggedIn: false,
            _isMounted: false,
            passwordChange: {
                oldPassword: "",
                newPassword: "",
                newVPassword: "",
            },
            user: {}
        }
    }

    changeScreen(screen) {
        this.setState({
            currentScreen: screen
        });
    }

    componentDidMount = async() => {
        document.title = "Account";

        try {
            const session = await Auth.currentSession();
            console.log(session);

            if(session.getIdToken()) {
                const user = await Auth.currentAuthenticatedUser();

                console.log(user);
                this.setState({
                    user: user,
                    _isMounted: true,
                    loggedIn: true,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                _isMounted: true,
                loggedIn: false,
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            _isMounted: false
        });
    }

    onOldChange(e) {
        const pwChange = this.state.passwordChange;

        pwChange.oldPassword = e.target.value;

        this.setState({
            passwordChange: pwChange
        })
    }

    onNewChange(e) {
        const pwChange = this.state.passwordChange;

        pwChange.newPassword = e.target.value;

        this.setState({
            passwordChange: pwChange
        })
    }

    onNewVChange(e) {
        const pwChange = this.state.passwordChange;

        pwChange.newVPassword = e.target.value;

        this.setState({
            passwordChange: pwChange
        })
    }

    handlePasswordChange(e) {
        e.preventDefault();

        const oldPassword = this.state.passwordChange.oldPassword;
        const newPassword = this.state.passwordChange.newPassword;

        Auth.changePassword(this.state.user, oldPassword, newPassword)
            .then(() => this.onSuccess().bind(this))
            .catch(err => console.error(err));
    }

    onSuccess() {
        this.setState({
            currentScreen: "main"
        });
        toast('Passwort geändert!');
    }

    render() {
        if(!this.state.loggedIn && this.state._isMounted) {
            return <Redirect to={"/login"} />;
        }

        if(!this.state._isMounted) {
            return <h1>Loading...</h1>
        } else {
            return <><Account
                user={this.state.user}
                cS={this.state.currentScreen}
                onCS={this.changeScreen.bind(this)}
                passwordChange={this.state.passwordChange}
                onOldChange={this.onOldChange.bind(this)}
                onNewChange={this.onNewChange.bind(this)}
                onNewVChange={this.onNewVChange.bind(this)}
                onPasswordChangeFormSubmit={this.handlePasswordChange.bind(this)}
            /><ToastContainer /></>
        }
    }
}

export default AccountContainer;