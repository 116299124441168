import React from 'react';
import PropTypes from 'prop-types';

class MMOdal extends React.Component {
    render() {
        // Render nothing if the show prop is false
        if(!this.props.show) {
            return null;
        }

        return (
            <div className="modal-wrapper">
                <div id={this.props.id} className="modal" style={{display: "block", zIndex: "1003"}}>
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                    <div className="modal-footer center">
                        <a onClick={this.props.onClose} href="#!" className="modal-close waves-effect waves-green btn-flat">OK</a>
                    </div>
                </div>
                <div className="modal-overlay" style={{zIndex: "1002", display: "block", opacity: "0.5"}}>

                </div>
            </div>
        );
    }
}

MMOdal.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    id: PropTypes.string.isRequired,
    children: PropTypes.node
};

export default MMOdal;