import React, { Component } from 'react';
import DashboardNavigation from "./DashboardNavigation";
import MainAccountDetails from "./account/Main";
import PasswordChange from "./account/PasswordChange";

export class Account extends Component {
    render() {
        return (
            <>
                <DashboardNavigation />
                <div id="dashboardContent" className="container">
                    <h1>Account Details</h1>
                    <div className="row">
                        <div className="col s12 m7">
                            <div className="card-panel white">
                                {this.props.cS === 'main' &&
                                    <MainAccountDetails email={this.props.user.username} />
                                }
                                {this.props.cS === 'password' &&
                                    <PasswordChange
                                        passwordChange={this.props.passwordChange}
                                        onOldChange={this.props.onOldChange}
                                        onNewChange={this.props.onNewChange}
                                        onNewVChange={this.props.onNewVChange}
                                        onFormSubmit={this.props.onPasswordChangeFormSubmit}
                                    />
                                }
                            </div>
                        </div>
                        <div className={"col m1"} />
                        <div className={"col m4"}>
                            <div className={"card-panel white"}>
                                <div className={"collection"}>
                                    <a href="#!" onClick={() => {this.props.onCS("main")}} className={"collection-item " + (this.props.cS === "main" && "active")}>Main</a>
                                    <a href="#!" onClick={() => {this.props.onCS("password")}} className={"collection-item " + (this.props.cS === "password" && "active")}>Passwort</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Account;