import React, { Component } from 'react';
import DashboardNavigation from "./DashboardNavigation";

export class Control extends Component {
    render() {
        return(
            <>
                <DashboardNavigation />
                <div className={"container"}>
                    <h1>Verwaltung</h1>
                    <p>Hier wird es eine Liste an Nutzern etc. geben, mit der Möglichkeit Neue hinzuzufügen etc. (Nur für Admin/Lehrer einsehbar)</p>
                </div>
            </>
        )
    }
}

export default Control;