import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { Redirect } from 'react-router';
import Dashboard from '../components/Dashboard';

Amplify.configure({
    Auth: {
        region: 'eu-central-1', // Amazon Cognito Region
        userPoolId: 'eu-central-1_uU4fcQaRU',
        userPoolWebClientId: '3ujs50l3k0vcaqjkg64r29h96o',
    }
});

export class DashboardContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            _isMounted: false,
            user: {}
        };
    }

    componentDidMount = async() => {
        document.title = "Dashboard";

        try {
            const session = await Auth.currentSession();
            console.log(session);

            if(session.getIdToken()) {
                const user = await Auth.currentAuthenticatedUser();

                console.log(user);
                this.setState({
                    user: user,
                    _isMounted: true,
                    loggedIn: true,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                _isMounted: true,
                loggedIn: false,
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            _isMounted: false
        });
    }

    render() {
        if(!this.state.loggedIn && this.state._isMounted) {
            return <Redirect to={"/login"} />;
        }

        if(!this.state._isMounted) {
            return <h1>Loading...</h1>
        } else {
            return <Dashboard
                              user={this.state.user}
                  />
        }
    }
}

export default DashboardContainer;