import React, { Component } from 'react';
import {Auth} from "aws-amplify";
import {Redirect} from "react-router";
import Control from "../components/Control";

export class ControlContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            _isMounted: false,
            user: {}
        }
    }

    componentDidMount = async() => {
        document.title = "Verwaltung";

        try {
            const session = await Auth.currentSession();
            console.log(session);

            if(session.getIdToken()) {
                const user = await Auth.currentAuthenticatedUser();

                console.log(user);
                this.setState({
                    user: user,
                    _isMounted: true,
                    loggedIn: true,
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                _isMounted: true,
                loggedIn: false,
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            _isMounted: false
        });
    }

    render() {
        if(!this.state.loggedIn && this.state._isMounted) {
            return <Redirect to={"/login"} />;
        }

        if(!this.state._isMounted) {
            return <h1>Loading...</h1>
        } else {
            return <Control
                user={this.state.user}
            />
        }
    }
}

export default ControlContainer;