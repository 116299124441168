import React, { Component } from 'react';
import {InputField} from "../helpers/InputField";
import {Button} from "../helpers/Button";

export class NewPasswordOnLogin extends Component {
    render() {
        return (
            <div id={"wrapper"} className={"row"}>
                <div id={"background-wrapper"} className={"col l1 hide-on-med-and-down"} />

                <div id={"login-wrapper"} className={"col s12 l5"}>
                    <h2>Passwort abgelaufen!</h2>
                    <p>Ihr aktuelles Passwort ist abgelaufen und Sie müssen ein neues wählen!</p>
                    <div className={"row"}>
                        <form id={"login-form"} className={"col s12"}>
                            <div className={"row"}>
                                <InputField
                                    classNames={"col s12"}
                                    name={"mvOnewpassword"}
                                    type={"password"}
                                    validate={true}
                                    autocomplete={false}
                                    label={"Neues Passwort"}
                                    pattern={".{8,}"}
                                    title={"Passwort benötigt 8 oder mehr Zeichen."}
                                    value={this.props.newPassword}
                                    onchange={this.props.updateNewPassword}
                                />
                            </div>
                            <div className={"row"}>
                                <InputField
                                    classNames={"col s12"}
                                    name={"mvOvnewpassword"}
                                    type={"password"}
                                    validate={true}
                                    autocomplete={false}
                                    label={"Passwort"}
                                    pattern={".{8,}"}
                                    title={"Passwort benötigt 8 oder mehr Zeichen."}
                                    value={this.props.verifyNewPassword}
                                    onchange={this.props.updateVerifyNewPassword}
                                />
                            </div>
                            <div className={"row"}>
                                <div className={"col s12"}>
                                    <Button
                                        type={"submit"}
                                        classNames={"col s5 waves-effect waves-light btn"}
                                        label={"Speichern"}
                                        clickHandler={this.props.onFormSubmit}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewPasswordOnLogin;