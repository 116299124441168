import React from 'react';
import {Link} from 'react-router-dom';

class DashboardNavigation extends React.Component {
    render() {
        return(
            <nav>
                <div className="nav-wrapper">
                    <Link className="brand-logo" to="/dashboard">T</Link>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <li><Link to={"/dashboard"}>Dashboard</Link></li>
                        <li><Link to={"/control"}>Verwalten</Link></li>
                        <li><Link to={"/account"}>Account</Link></li>
                        <li><Link to={"/logout"}>Logout</Link></li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default DashboardNavigation;