import React, { Component } from 'react';

export class MainAccountDetails extends Component {
    render() {
        return(
            <>
                <p>Hier könnten ein paar Informationen über den Account zu sehen sein.</p>
                <ul>
                    <li>Email: {this.props.email}</li>
                </ul>
            </>
        )
    }
}

export default MainAccountDetails;