import React from 'react';
import {InputField} from "../helpers/InputField";
import {Button} from "../helpers/Button";

import {withRouter} from 'react-router-dom';

export class ForgottenPassword extends React.Component {
    render() {
        return (
            <div id='wrapper' className="row">
                <div id="background-wrapper" className="col l1 hide-on-med-and-down">

                </div>
                <div id="login-wrapper" className="col s12 l5">
                    <h1>Forgot Password?</h1>

                    <div className="row">
                        <form id="verify-form" className="col s12">
                            <div className="row">
                                <InputField
                                    classNames="col s12"
                                    name="emailInputReset"
                                    type="email"
                                    validate={true}
                                    autocomplete={false}
                                    label="E-Mail"
                                    onchange={this.props.onEmailUpdate}
                                    value={this.props.email}
                                />
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <Button
                                        type="button"
                                        classNames="col s5 waves-effect waves-light btn"
                                        label="Back"
                                        clickHandler={this.props.history.goBack}
                                    />
                                    <div className="col s2">

                                    </div>
                                    <Button
                                        type="submit"
                                        classNames="col s5 waves-effect waves-light btn"
                                        label="Submit"
                                        id="register-btn"
                                        clickHandler={this.props.onSubmit}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col l6 hide-on-med-and-down">

                </div>
            </div>
        );
    }
}

export default withRouter(ForgottenPassword);