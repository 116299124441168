import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './aws-exports';

// Import Routing Components
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Import Components for Routing
import LoginContainer from "./containers/LoginContainer";
import DashboardContainer from "./containers/DashboardContainer";
import AccountContainer from "./containers/AccountContainer";
import LogoutContainer from "./containers/LogoutContainer";
import ForgottenPasswordContainer from "./containers/ForgottenPasswordContainer";
import ControlContainer from "./containers/ControlContainer";

Amplify.configure(config);

const routing =
    <BrowserRouter>
        <Switch>
            <Route exact path={"/"} component={LoginContainer} />
            <Route path={"/login"} component={LoginContainer} />
            <Route path={"/dashboard"} component={DashboardContainer} />
            <Route path={"/pw_forgotten"} component={ForgottenPasswordContainer} />
            <Route path={"/account"} component={AccountContainer} />
            <Route path={"/control"} component={ControlContainer} />
            <Route path={"/logout"} component={LogoutContainer} />
        </Switch>
    </BrowserRouter>;

ReactDOM.render(
    routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
