import React from 'react';
import {InputField} from "../helpers/InputField";
import {Button} from "../helpers/Button";
import MMOdal from "../helpers/MMOdal";

export class ForgottenPasswordSubmit extends React.Component {
    render() {
        return(
            <div id='wrapper' className="row">
                <div id="background-wrapper" className="col l1 hide-on-med-and-down">

                </div>
                <div id="login-wrapper" className="col s12 l5">
                    <h1>Forgot Password?</h1>

                    <div className="row">
                        <form id="verify-form" className="col s12">
                            <div className="row">
                                <InputField
                                    classNames="col s12"
                                    name="emailInputReset"
                                    type="email"
                                    validate={true}
                                    autocomplete={false}
                                    label="E-Mail"
                                    onchange={this.props.onEmailUpdate}
                                    value={this.props.email}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    classNames="col s12"
                                    name="codeInputReset"
                                    type="text"
                                    validate={true}
                                    autocomplete={false}
                                    label="Code"
                                    onchange={this.props.onCodeUpdate}
                                    value={this.props.code}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    classNames="col s12"
                                    name="passwordInputReset"
                                    type="password"
                                    validate={true}
                                    autocomplete={false}
                                    pattern=".{8,}"
                                    title="8 or more characters required"
                                    label="New Password"
                                    onchange={this.props.onNewPasswordUpdate}
                                    value={this.props.newPassword}
                                />
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <div className="col s4">

                                    </div>
                                    <Button
                                        type="submit"
                                        classNames="col s4 waves-effect waves-light btn"
                                        label="Submit"
                                        id="register-btn"
                                        clickHandler={this.props.onSubmit}
                                    />
                                    <div className="col s4">

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col l6 hide-on-med-and-down">

                </div>
                <MMOdal
                    id="password-reset-success-modal"
                    onClose={this.props.afterSuccess}
                    show={this.props.showModal}>
                    <h1>Success</h1>
                    <p>
                        You reset your password. You can now login with your new password.
                    </p>
                </MMOdal>
            </div>
        );
    }
}

export default ForgottenPasswordSubmit;