import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {Redirect} from "react-router";

export class LogoutContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            doneLoading: false
        }
    }

    componentDidMount() {
        Auth.signOut().then(() => {this.setState({doneLoading:true})});
    }

    render() {
        if(!this.state.doneLoading) {
            return <h1>Loading....</h1>
        } else {
            return <Redirect to={"/login"} />
        }
    }
}

export default LogoutContainer;