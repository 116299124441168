import React, { Component } from 'react';

export class InputField extends Component {
    render() {
        const validate = this.props.validate ? "validate" : "";
        const autocomplete = this.props.autocomplete ? "on" : "off";
        const pattern = this.props.pattern ? this.props.pattern : "";
        const title = this.props.title ? this.props.title : "";
        const onChangeHandler = this.props.onchange ? this.props.onchange : null;
        const value = this.props.value ? this.props.value : "";

        if(pattern === "") {
            return (
                <div className={["input-field", this.props.classNames].join(" ")}>
                    <input id={this.props.name}
                           type={this.props.type}
                           name={this.props.name}
                           className={validate}
                           autoComplete={autocomplete}
                           title={title}
                           onChange={onChangeHandler}
                           value={value}
                    />
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                </div>
            );
        } else {
            return (
                <div className={["input-field", this.props.classNames].join(" ")}>
                    <input id={this.props.name}
                           type={this.props.type}
                           name={this.props.name}
                           className={validate}
                           autoComplete={autocomplete}
                           title={title}
                           pattern={pattern}
                           onChange={onChangeHandler}
                           value={value}
                    />
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                </div>
            );
        }

    }
}

export default InputField;