import React, { Component } from 'react';
import InputField from "../../helpers/InputField";
import {Button} from "../../helpers/Button";

export class PasswordChange extends Component {
    render() {
        return(
            <>
                <span className={"card-title"}>Passwort ändern</span>
                <form>
                    <div className={"row"}>
                        <InputField
                            classNames={"col s12"}
                            name={"mvOoldPassword"}
                            type={"password"}
                            validate={true}
                            autocomplete={false}
                            label={"Altes Passwort"}
                            pattern={".{8,}"}
                            title={"Passwort benötigt 8 oder mehr Zeichen."}
                            value={this.props.passwordChange.oldPassword}
                            onchange={this.props.onOldChange}
                        />
                    </div>
                    <div className={"row"}>
                        <InputField
                            classNames={"col s12"}
                            name={"mvOnewPassword"}
                            type={"password"}
                            validate={true}
                            autocomplete={false}
                            label={"Neues Passwort"}
                            pattern={".{8,}"}
                            title={"Passwort benötigt 8 oder mehr Zeichen."}
                            value={this.props.passwordChange.newPassword}
                            onchange={this.props.onNewChange}
                        />
                    </div>
                    <div className={"row"}>
                        <InputField
                            classNames={"col s12"}
                            name={"mvOnewVPassword"}
                            type={"password"}
                            validate={true}
                            autocomplete={false}
                            label={"Neues Passwort verifizieren"}
                            pattern={".{8,}"}
                            title={"Passwort benötigt 8 oder mehr Zeichen."}
                            value={this.props.passwordChange.newVPassword}
                            onchange={this.props.onNewVChange}
                        />
                    </div>
                    <div className={"row"}>
                        <div className={"col s12"}>
                            <Button
                                type={"submit"}
                                classNames={"col s5 waves-effect waves-light btn"}
                                label={"Speichern!"}
                                clickHandler={this.props.onFormSubmit}
                            />
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

export default PasswordChange;