import React, { Component } from 'react';

export class Button extends Component {
    render() {
        const id = this.props.id ? this.props.id : "";
        const onclick = this.props.clickHandler ? this.props.clickHandler : null;

        if(id === "") {
            return (
                <button type={this.props.type}
                        className={this.props.classNames}
                        onClick={onclick}>
                    {this.props.label}
                </button>
            );
        } else {
            return (
                <button type={this.props.type}
                        className={this.props.classNames}
                        id={id}
                        onClick={onclick}>
                    {this.props.label}
                </button>
            );
        }
    }
}