import React from 'react';
import ForgottenPassword from '../components/ForgottenPassword';
import {Auth} from "aws-amplify";
import ForgottenPasswordSubmit from "../components/ForgottenPasswordSubmit";
import {Redirect} from "react-router";

export class ForgottenPasswordContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            username: "",
            code: "",
            newPassword: "",
            requestSent: false,
            showPasswordResetSuccess: false,
            redirect: false,
            redirectTo: ""
        };

        this.updateEmail = this.updateEmail.bind(this);
        this.updateCode = this.updateCode.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.onForm1Submit = this.onForm1Submit.bind(this);
        this.onForm2Submit = this.onForm2Submit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onSuccess2 = this.onSuccess2.bind(this);
        this.afterSuccess = this.afterSuccess.bind(this);
    }

    updateEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    updateCode(e) {
        this.setState({
            code: e.target.value
        });
    }

    updatePassword(e) {
        this.setState({
            newPassword: e.target.value
        });
    }

    onForm1Submit(e) {
        e.preventDefault();

        const username = this.state.email;

        this.setState({
            username: username
        });

        Auth.forgotPassword(username)
            .then(() => this.onSuccess())
            .catch(err => console.error(err));
    }

    onForm2Submit(e) {
        e.preventDefault();

        const username = this.state.email;
        const code = this.state.code;
        const newPassword = this.state.newPassword;

        Auth.forgotPasswordSubmit(username, code, newPassword)
            .then(() => this.onSuccess2())
            .catch(err => console.error(err));
    }

    onSuccess() {
        this.setState({
            requestSent: true
        });
    }

    onSuccess2() {
        this.setState({
            showPasswordResetSuccess: true
        });
    }

    afterSuccess() {
        this.setState({
            showPasswordResetSuccess: false,
            redirect: true,
            redirectTo: "/login"
        });
    }

    render() {
        if(this.state.redirect) {
            return <Redirect to={this.state.redirectTo} />;
        }
        if(this.state.requestSent) {
            return(
                <ForgottenPasswordSubmit
                    email={this.state.email}
                    onEmailUpdate={this.updateEmail}
                    code={this.state.code}
                    onCodeUpdate={this.updateCode}
                    newPassword={this.state.newPassword}
                    onNewPasswordUpdate={this.updatePassword}
                    onSubmit={this.onForm2Submit}
                    afterSuccess={this.afterSuccess}
                    showModal={this.state.showPasswordResetSuccess}
                />
            )
        } else {
            return(
                <ForgottenPassword
                    email={this.state.email}
                    onEmailUpdate={this.updateEmail}
                    onSubmit={this.onForm1Submit}
                />
            );
        }
    }
}

export default ForgottenPasswordContainer;